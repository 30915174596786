import { TMarket } from '@/src/types';
import { createArchieStoreCreator, createHook } from '@awning/archie';

type TUserStore = {
  user: TUser;
  isLoggedIn: () => boolean;
  setUser: (user: TUser | Partial<TUser>, reset?: boolean) => void;
};

export const userStoreCreator = createArchieStoreCreator<TUserStore>({
  name: 'userStore',
  stateInitializer: (set, get) => ({
    user: {} as TUser,
    isLoggedIn: () => !!(get().user?.firstName && get().user?.id),
    setUser: (user, reset = false) =>
      reset ? set({ user: user as TUser }) : set({ user: { ...get().user, ...user } }),
  }),
});

export const useUserStore = createHook(userStoreCreator);

export type TUser = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  isActive: boolean;
  lastLogin: null;
  roles: number[];
  meta: UserMeta;
  abTestLegs: Array<TABTestLegs>;
  accountType: 'individual' | 'awning_employee' | 'company' | 'vendor_partner';
  markets: Array<TMarket>;
  status: null | 'new_account' | 'exploring' | 'actively_looking' | 'inactive' | 'offer_stage';
  affiliateRef: null | string;
  referralCode: string;
};

type TABTestLegs = {
  id: string;
  legName: string;
  weight: number;
  abTest: {
    id: string;
    testName: string;
    description: string;
    enabled: boolean;
  };
};

export type UserMeta = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  notes: string | null;
  stage: string; // 'new_user'
  userPersona: null | 'established_investor' | 'aspiring_investor' | 'high_net_worth';
  strategy: string | null;
  lastEngagement: string | null;
  lastTouched: Date | null;
  leadSource: string | null;
  campaignSource: string | null;
  referralSource: string | null;
  closedLostDetail: string | null;
  closedLostReason: string | null;
  needsHelpWithStrategies: any; // Todo: type correctly
  needsHelpWithMarkets: any; // Todo: type correctly
  additionalMarkets: any; // Todo: type correctly
  annualIncome: number | null;
  cashAvailable: number | null;
  preapprovedForMortgageYn: string | null;
  maxPurchasePrice: number | null;
  investmentTimeframe: string | null;
  propertyManagerYn: string | null;
  userQualityScore: string | null;
  introCallScheduledAt: Date | null;
  introCallCompletedAt: Date | null;
  introCallScheduledFor: any; // Todo: type correctly
  introCallMissed: any; // Todo: type correctly
  blockers:
    | null
    | 'rei_education'
    | 'market_education'
    | 'not_a_priority'
    | 'strategy_matched_properties'
    | 'timing'
    | 'not_interested_in_product'
    | 'geography'
    | 'unknown_went_dark';
  numMeetings: any; // Todo: type correctly
  primaryMarket: any; // Todo: type correctly
  hasRenovationInterest: any; // Todo: type correctly
  hasConsistentIncome: any; // Todo: type correctly
  financingType: any; // Todo: type correctly
  financingNotes: any; // Todo: type correctly
  introducedLenders: any; // Todo: type correctly
  preApprovalLetter: null | string;
  preApprovalLetterCreatedAt: Date | null;
  preApprovalLetterUpdatedAt: Date | null;
  bankStatement: null | string;
  bankStatementCreatedAt: Date | null;
  bankStatementUpdatedAt: Date | null;
  user: string;
};
