import * as React from 'react';
import { createSingletonStore, shallow } from '@awning/archie';
import { BaseLink } from '@/src/BaseLink';
import {
  AwningLogo,
  Box,
  Container,
  Flex,
  LinkButton,
  SmallAwningLogo,
  theme,
} from '@awning/components';
import { useRouter } from 'next/router';
import { ProfileDropdown } from './ProfileDropdown';
import { NavigationLink } from './NavigationLink';
import { useUserStore } from '@/src/shared/userStore';

export const navRef = React.createRef<HTMLElement>();

export const useNavigationStore = createSingletonStore<{
  show: boolean;
  showChildren: boolean;
  setShow: (v: boolean) => void;
  setShowAll: (v: boolean) => void;
  toggle: () => void;
  toggleAll: () => void;
}>((set, get) => ({
  show: true,
  showChildren: true,
  setShow: v => set({ show: v }),
  setShowAll: v => set({ show: v, showChildren: v }),
  toggle: () => set({ show: !get().show }),
  toggleAll: () => set({ show: !get().show, showChildren: !get().showChildren }),
}));

export const Navigation: React.FC<
  React.PropsWithChildren<{
    /*
     * On certain pages like the Search page we want the navigation and sub navigation to expand and fill
     * the width of the screen. Older designs use the centered design.
     */
    centerContainerContent?: boolean;
    render?: (c: React.FC<React.PropsWithChildren<unknown>>) => JSX.Element | React.Component;
  }>
> = ({ centerContainerContent = true, render }) => {
  const { asPath } = useRouter();
  const isLoggedIn = useUserStore(state => state.isLoggedIn?.());

  const [show, showChildren, setShow] = useNavigationStore(
    state => [state.show, state.showChildren, state.setShow],
    shallow
  );

  React.useEffect(() => {
    return () => {
      // reset the show state of the main navigation when unmounting.
      // fixes the issue we have some time when navigating between pages and the wrong 'height' is shown - or the entire nav is hidden
      setShow(true);
    };
  }, []); // eslint-disable-line

  const containerStyle = centerContainerContent
    ? { paddingX: { base: 4, lg: 0 } }
    : {
        paddingX: 4,
        [`@media (min-width: ${theme.breakpoints[2]})`]: {
          width: '100%',
          maxWidth: '100%',
        },
      };

  const style = show
    ? {
        opacity: 1,
        boxShadow: '0px 1px 1px  #D8D9DB',
        visibility: 'visible',
        height: '100%',
      }
    : {
        opacity: 0,
        visibility: 'hidden',
        height: '0px',
      };

  const renderChildren = () => {
    if (showChildren && render) {
      const InnerContainer = (props: any) => (
        <Container sx={{ ...containerStyle }}>{props.children}</Container>
      );
      return (
        <Box
          sx={{
            text: 'sm',
            backgroundColor: 'white',
            transition: 'opacity 0.1s ease-in',
            boxShadow: '1px -1px 1px #D8D9DB, 0 1px 1px #D8D9DB',
          }}
        >
          {render(InnerContainer)}
        </Box>
      );
    }
    return null;
  };

  return (
    <Box
      sx={{
        position: { base: 'relative', md: 'sticky' },
        width: '100%',
        backgroundColor: 'white',
        top: '0px !important',
        transform: 'translateZ(0px) !important',
        zIndex: 99,
      }}
      ref={navRef}
    >
      <Box
        sx={{
          text: 'sm',
          backgroundColor: 'white',
          transition: 'opacity 0.1s ease-in',
          ...style,
        }}
      >
        <Container sx={{ ...containerStyle }}>
          <Flex
            sx={{
              text: 'sm',
              backgroundColor: 'white',
              paddingY: 2,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <BaseLink href="https://awning.com/">
              <Box
                as={SmallAwningLogo}
                textColor="black"
                sx={{ display: { base: 'block', md: 'none' }, fontSize: 'lg' }}
              />
              <Box as={AwningLogo} sx={{ display: { base: 'none', md: 'block' } }} />
            </BaseLink>

            <Flex sx={{ width: '100%', alignItems: 'center', justifyContent: 'flex-end' }}>
              {isLoggedIn ? (
                <>
                  <NavigationLink
                    isActive={false}
                    href="https://www.awning.com/airbnb-estimator"
                    title="Estimator"
                  />

                  <Flex
                    sx={{
                      marginLeft: 6,
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <ProfileDropdown />
                  </Flex>
                </>
              ) : (
                <Flex sx={{ gap: 4, width: '100%', justifyContent: 'flex-end' }}>
                  <Box>
                    <LinkButton
                      variant="secondary"
                      size="md"
                      href={`${process.env.NEXT_PUBLIC_BASE_PATH}/accounts/login`}
                      sx={{ whiteSpace: 'nowrap' }}
                    >
                      Sign in
                    </LinkButton>
                  </Box>
                  <Box>
                    <LinkButton
                      size="md"
                      sx={{ whiteSpace: 'nowrap' }}
                      href={`${process.env.NEXT_PUBLIC_BASE_PATH}/sign-up`}
                    >
                      Join Awning
                    </LinkButton>
                  </Box>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Container>
      </Box>
      {renderChildren()}
    </Box>
  );
};
