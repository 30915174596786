import * as React from 'react';
import { BaseLink } from '@/src/BaseLink';
import { Text } from '@awning/components';

type NavigationLinkProps = {
  isActive: boolean;
  href: string;
  title: string | React.ReactNode;
  shortTitle?: string | React.ReactNode;
  className?: string;
};

export const NavigationLink: React.FC<React.PropsWithChildren<NavigationLinkProps>> = ({
  isActive,
  href,
  title,
  shortTitle,
  className = '',
  children,
}) => {
  return (
    <BaseLink
      href={href}
      sx={{
        textDecoration: 'none',
        position: 'relative',
        cursor: 'pointer',
      }}
    >
      <Text
        className={className}
        sx={{
          marginLeft: 4,
          fontWeight: isActive ? 'bold' : 'normal',
          display: { base: 'none', lg: 'block' },
        }}
      >
        {title}
      </Text>
      <Text
        className={className}
        sx={{
          marginLeft: 4,
          display: { base: 'block', lg: 'none' },
          fontWeight: isActive ? 'bold' : 'normal',
        }}
      >
        {shortTitle ? shortTitle : title}
      </Text>
      {children}
    </BaseLink>
  );
};
