import * as React from 'react';
import { Box, TStyleProps } from '@awning/components';

type BaseLinkProps = {
  newTab?: null | boolean;
  sx?: TStyleProps['sx'];
  onMouseEnter?: React.MouseEventHandler<Element> | undefined;
  onClick?: React.MouseEventHandler;
  href?: string | undefined;
  ref?: any;
};

export const BaseLink: React.FC<React.PropsWithChildren<BaseLinkProps>> = props => {
  const { sx = {}, newTab = false, href, children, onClick, ...rest } = props;

  if (newTab)
    return (
      <Box
        as="a"
        href={href as string}
        target="_blank"
        rel="noreferrer"
        sx={sx}
        onClick={onClick}
        {...rest}
      >
        {children}
      </Box>
    );

  const _h = href?.includes('http') ? href : `${process.env.NEXT_PUBLIC_BASE_PATH}${href}`;

  return (
    <Box as="a" href={_h} onClick={onClick} sx={sx} {...rest}>
      {children}
    </Box>
  );
};
